

























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question543AI',
  components: {
    STextarea,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{GaH3}$', value: 'GaH3'},
        {text: '$\\ce{NH3}$', value: 'NH3'},
        {text: '$\\ce{H2O}$', value: 'H2O'},
        {text: '$\\ce{Cl-}$', value: 'Cl-'},
        {text: '$\\ce{Co^3+}$', value: 'Co3+'},
        {text: '$\\ce{H-}$', value: 'H-'},
        {text: '$\\ce{H+}$', value: 'H+'},
        {text: '$\\ce{BH3}$', value: 'BH3'},
        {text: '$\\ce{SCN-}$', value: 'SCN-'},
        {text: '$\\ce{CN-}$', value: 'CN-'},
        {text: '$\\ce{N2}$', value: 'N2'},
        {text: '$\\ce{CH3OH}$', value: 'CH3OH'},
      ],
    };
  },
  computed: {
    versionNumber(): string {
      return '/img/assignments/organicVsWaterLayer2.png';
    },
  },
};
